<template>
  <v-text-field
    :label="label"
    :value="value"
    :readonly="options ? options.readonly : false"
    @change="change"
    filled
    background-color="transparent"
    hide-details
  >
  </v-text-field>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: ["label", "value", "options"],
  data() {
    return { type: "string" };
  },
  mounted() {
    this.type = typeof this.value;
  },
  methods: {
    change(value) {
      switch (this.type) {
        case "number":
          this.$emit("change", parseFloat(value));
          break;
        case "string":
          this.$emit("change", value);
          break;
      }
    },
  },
};
</script>
